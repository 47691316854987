import { defaultAction } from "./defaultAction";
import { reset as checkoutReset } from "@modules/Checkout/constant";
import {
  OPEN_ERROR_BOX,
  SHOW_DEVICE_VERIFY_POPUP_MODAL
} from "@constants/actionTypes";
import {
  DISPLAY_CUSTOMER_ALREADY_EXISTS,
  HIDE_SIGN_IN_POPUP_MODAL,
  SET_LOADER_FOR_SIGN_IN,
  HIDE_SIGNIN_PASS_ERROR,
  SET_RECAPTCHA,
  RESET_RECAPTCHA,
  DISPLAY_RECAPTCHA,
  SHOW_ACCOUNT_LOCKED,
  DISPLAY_SIGNIN_PASS_ERROR,
  SET_CUSTOMER_VERIFICATION_EMAIL,
  SET_CUSTOMER_VERIFICATION_ID
} from "@constants/accountTypes";

import {
  SHOW_REFER_POPUP_MODAL,
  SHOW_REFER_SIGN_UP_STATUS_POPUP_MODAL,
  SET_REFERRAL_DATA
} from "@constants/refer";
import { errorResponseMessage } from "./errorResponseMessage";
import { getCustomerProfile } from "./getCustomerProfile";
import { getPaymentMethods } from "./getPaymentMethods";
import { getCartsSignIn } from "./getCartsSignIn";
import { sendBugsnagError } from "@utils/Bugsnag";

export const createAccount =
  (bodyToSendToServer, ctx) => async (dispatch, getState, api) => {
    try {
      const profileData = await api.post("v3.1/customers", bodyToSendToServer);
      if (profileData.data.referralStatus) {
        dispatch(
          defaultAction(SET_REFERRAL_DATA, {
            referralStatus: profileData.data.referralStatus,
            referredCoupon: profileData.data.referredCoupon
          })
        );
      }

      dispatch(defaultAction(checkoutReset));
      const forwardLocation =
        ctx.props.location.state && ctx.props.location.state.from
          ? ctx.props.location.state.from
          : "/";
      if (profileData.data.deviceVerified) {
        dispatch(
          loginUser(
            bodyToSendToServer.customer.email,
            bodyToSendToServer.customer.password,
            null,
            forwardLocation
          )
        );
      } else {
        dispatch(
          defaultAction(
            SET_CUSTOMER_VERIFICATION_EMAIL,
            bodyToSendToServer.customer.email
          )
        );
        dispatch(
          defaultAction(
            SET_CUSTOMER_VERIFICATION_ID,
            profileData.data.customerId
          )
        );
        dispatch(defaultAction(SHOW_DEVICE_VERIFY_POPUP_MODAL, true));
      }
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "createAccount");
    } catch (error) {
      const errorMessage = errorResponseMessage(error);
      if (errorMessage === "Customer already exists") {
        dispatch(defaultAction(DISPLAY_CUSTOMER_ALREADY_EXISTS, true));
        return {
          error: errorMessage || "Something went wrong please try again."
        };
      } else {
        sendBugsnagError(error, "actions/createAccount");
        dispatch(
          defaultAction(
            OPEN_ERROR_BOX,
            "Something went wrong please try again."
          )
        );
      }
      return {
        error: errorMessage || "Something went wrong please try again."
      };
    }
  };

export const loginUser =
  (email, password, reCaptcha, ctx) =>
  async (dispatch, getState, api, services) => {
    dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, "userName"));
    try {
      let bodyToSendToServer = reCaptcha
        ? { customer: { email, password, reCaptcha } }
        : { customer: { email, password } };
      dispatch(defaultAction(checkoutReset));
      const response = await api.post(
        "v3.1/customers/login",
        bodyToSendToServer
      );

      const params = {
        response,
        ctx,
        dispatch,
        emailSentFromBody: email
      };
      dispatch(defaultAction(DISPLAY_RECAPTCHA, false));
      await redirectToVerificationOrLogin(params);
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "completeLogin");
      dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, ""));
      return "success";
    } catch (error) {
      sendBugsnagError(error, "actions/loginUser");

      let errorMessage = errorResponseMessage(error);
      const { grecaptcha } = await services.getGlobals("grecaptcha");

      if (
        errorMessage === "reCaptcha required" ||
        errorMessage === "reCaptcha failed"
      ) {
        if (document.querySelector("#recaptchaTarget iframe")) {
          // already rendered
          grecaptcha.reset();
        } else {
          grecaptcha.render(document.getElementById("recaptchaTarget"), {
            sitekey: "6LdD7SgTAAAAALjVAm80uYL-CKmPlF1Wwt0CYjv4",
            callback: data => {
              dispatch(defaultAction(SET_RECAPTCHA, data));
            },
            "expired-callback"() {
              dispatch(defaultAction(RESET_RECAPTCHA));
            }
          });
        }
        dispatch(defaultAction(DISPLAY_RECAPTCHA, true));
      } else if (
        errorMessage.startsWith("Customer Account is in force reset") ||
        errorMessage === "customer account is locked"
      ) {
        dispatch(defaultAction(SHOW_ACCOUNT_LOCKED, true));
        ctx.props.history.push("/pass-assist/");
      } else if (
        errorMessage === "Invalid Email/Password" &&
        getState().customerInfo.showRecaptcha &&
        document.querySelector("#recaptchaTarget iframe")
      ) {
        grecaptcha.reset();
      }

      if (!errorMessage) {
        errorMessage = "Something went wrong, please refresh your page.";
      }

      dispatch(defaultAction(DISPLAY_SIGNIN_PASS_ERROR, errorMessage));
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "errorLogin", error);
      dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, ""));
      return { error: errorMessage };
    }
  };

const redirectToVerificationOrLogin = async ({
  response,
  ctx,
  dispatch,
  emailSentFromBody = ""
}) => {
  if (
    response.data.hasOwnProperty("deviceVerified") &&
    !response.data.deviceVerified
  ) {
    const { email, customerEmail, customerId } = response.data;
    const verificationEmail = emailSentFromBody
      ? emailSentFromBody
      : email
      ? email
      : customerEmail;
    dispatch(defaultAction(SET_CUSTOMER_VERIFICATION_EMAIL, verificationEmail));
    dispatch(defaultAction(SET_CUSTOMER_VERIFICATION_ID, customerId));
    dispatch(defaultAction(SHOW_DEVICE_VERIFY_POPUP_MODAL, true));
  } else {
    dispatch(getCartsSignIn());
    await dispatch(getCustomerProfile());
    await dispatch(getPaymentMethods());
    dispatch(defaultAction(HIDE_SIGN_IN_POPUP_MODAL));
    dispatch(defaultAction(HIDE_SIGNIN_PASS_ERROR));
    handleRedirectOnLogin({ dispatch, ctx });
  }
};

export const loginGoogle =
  (access_token, ctx) => async (dispatch, getState, api) => {
    try {
      dispatch(defaultAction(checkoutReset));
      const response = await api.post("v3.1/customers/login/google", {
        access_token
      });

      if (response.data.referralStatus) {
        dispatch(
          defaultAction(SET_REFERRAL_DATA, {
            referralStatus: response.data.referralStatus,
            referredCoupon: response.data.referredCoupon
          })
        );
      }

      const params = {
        response,
        ctx,
        dispatch
      };
      await redirectToVerificationOrLogin(params);
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "completeLogin");
      return "success";
    } catch (error) {
      sendBugsnagError(error, "actions/loginGoogle");
      if (error?.response?.data?.missingData) {
        return {
          error: {
            ...error.response.data
          }
        };
      }
      let errorMessage = errorResponseMessage(error);
      if (!errorMessage) {
        errorMessage = "Something went wrong, please refresh your page.";
      } else if (
        errorMessage.startsWith("Customer Account is in force reset") ||
        errorMessage === "customer account is locked"
      ) {
        dispatch(defaultAction(SHOW_ACCOUNT_LOCKED, true));
        ctx.props.history.push("/pass-assist/");
      }
      dispatch(defaultAction(DISPLAY_SIGNIN_PASS_ERROR, errorMessage));

      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "errorLogin", error);
    } finally {
      dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, ""));
    }
  };

export const loginUserPopup =
  (email, password, reCaptcha, ctx) =>
  async (dispatch, getState, api, services) => {
    dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, "userName"));
    try {
      let bodyToSendToServer;
      if (reCaptcha) {
        bodyToSendToServer = { customer: { email, password, reCaptcha } };
      } else {
        bodyToSendToServer = { customer: { email, password } };
      }
      dispatch(defaultAction(checkoutReset));
      const response = await api.post(
        "v3.1/customers/login",
        bodyToSendToServer
      );
      const params = {
        response,
        ctx,
        dispatch,
        emailSentFromBody: email
      };
      dispatch(defaultAction(DISPLAY_RECAPTCHA, false));
      await redirectToVerificationOrLogin(params);
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "completeLogin");
      dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, ""));
      return "success";
    } catch (error) {
      sendBugsnagError(error, "actions/loginUserPopup");
      const errorMessage = errorResponseMessage(error);
      const { grecaptcha } = await services.getGlobals("grecaptcha");
      const { customerInfo } = getState();
      if (
        errorMessage === "reCaptcha required" ||
        errorMessage === "reCaptcha failed"
      ) {
        if (document.querySelector("#recaptchaTargetPopup iframe")) {
          // already rendered
          grecaptcha.reset();
        } else {
          grecaptcha.render(document.getElementById("recaptchaTargetPopup"), {
            sitekey: "6LdD7SgTAAAAALjVAm80uYL-CKmPlF1Wwt0CYjv4",
            callback: data => {
              dispatch(defaultAction(SET_RECAPTCHA, data));
            },
            "expired-callback"() {
              dispatch(defaultAction(RESET_RECAPTCHA));
            }
          });
        }
        dispatch(defaultAction(DISPLAY_RECAPTCHA, true));
      } else if (
        errorMessage === "Invalid Email/Password" &&
        customerInfo.showRecaptcha
      ) {
        grecaptcha.reset();
      }
      if (
        errorMessage.startsWith("Customer Account is in force reset") ||
        errorMessage === "customer account is locked"
      ) {
        dispatch(defaultAction(SHOW_ACCOUNT_LOCKED, true));
        ctx.props.history.push("/pass-assist/");
      }
      dispatch(
        defaultAction(
          DISPLAY_SIGNIN_PASS_ERROR,
          errorMessage || "Something went wrong please refresh your page."
        )
      );
      global.ga &&
        global.ga("send", "event", "buyMicroConversions", "errorLogin", error);
      dispatch(defaultAction(SET_LOADER_FOR_SIGN_IN, ""));
      return { error: errorMessage };
    }
  };

export const handleRedirectOnLogin = ({ dispatch, ctx }) => {
  const {
    location: { pathname },
    history
  } = ctx.props;
  if (
    ctx.props.location.state &&
    Object.keys(ctx.props.location.state).length > 0
  ) {
    const pushToCheckoutArray = ["popupCart", "cartPage", "navBar"];
    const referArray = ["homeRefer", "footerRefer"];
    if (pushToCheckoutArray.includes(ctx.props.location.state.initiator)) {
      history.push({
        pathname: "/checkout/",
        state: { ...ctx.props.location.state }
      });
    } else if (referArray.includes(ctx.props.location.state.initiator)) {
      dispatch(defaultAction(SHOW_REFER_POPUP_MODAL, true));
    } else if (ctx.props.location.state.initiator === "sellPage") {
      history.push({
        pathname: "/payout/",
        state: { ...ctx.props.location.state }
      });
    } else {
      if (ctx.props.location.state?.from?.startsWith("/pass-assist")) {
        history.push("/");
      } else {
        history.push({
          pathname: ctx.props.location.state.from,
          state: { ...ctx.props.location.state }
        });
      }
    }
  } else {
    if (pathname.includes("step-two")) {
      history.push("/payout/");
    } else if (pathname.includes("cart")) {
      history.push("/checkout/");
    } else {
      history.push("/");
    }
  }
  dispatch(setReferralStatus());
};

const setReferralStatus = () => async (dispatch, getState) => {
  try {
    const { queryParams } = getState().marketing;
    if (
      queryParams &&
      queryParams.utm_source === "cardcash" &&
      queryParams.utm_medium === "referral" &&
      queryParams.utm_content
    ) {
      dispatch(defaultAction(SHOW_REFER_SIGN_UP_STATUS_POPUP_MODAL, true));
    }
  } catch (error) {
    sendBugsnagError(error, "actions/setReferralStatus");
  }
};

export const initializeRecaptcha =
  service => async (dispatch, getState, api, services) => {
    await services.initializeRecaptcha(service);
  };
